import './App.css';
import MainScreen from './screens/MainScreen';
import { BsArrowLeftCircleFill } from "react-icons/bs"
import {useState} from 'react'
import { AiTwotoneCloud } from "react-icons/ai";
import { SideMenu } from './components/SideMenu';
function App() {
  const [open,setOpen]= useState(true);
  
  return (
    <div className="flex flex-row bg-slate-200">
      <div 
        className={`bg-[#6c9fa9] h-screen p-5 pt-8 ${open ? "w-72" : "w-24" } duration-200 relative`}
      >
      <BsArrowLeftCircleFill 
        className={`bg-[#6c9fa9] text-white text-3xl rounded-full absolute -right-3 top-9
        border border-[6c9fa9] cursor-pointer ${!open && "rotate-180"}`} onClick={()=> setOpen(!open)}
          />

        <div 
          className="inline-flex cursor-pointer ">
          <AiTwotoneCloud className="text-white bg-[#6c9fa9] rounded cursor-pointer block float-left text-4xl mr-2 " onClick={()=>{}}/>
          <h1 className={` text-white origin-left text-1xl font-medium mt-1 duration-200  ${!open && "scale-0"} `}>TopSoft</h1>
        </div>
        
        <ul
          className={`pt-2 ${open ? "border-t border-white": "pt-2"}`}>
          {SideMenu.map((menu,index) => (
          <>
          <li key={index} className="text-white text-sm flex items-center gap-x-3 cursor-pointer p-2
          hover:bg-slate-400 rounded-md mt-2">
            <span className="text-1xl block float-left">
            {menu.icon}
            </span>
            <span className ={`text-base font-medium flex-1 duration-200 ${!open && "hidden"}`}>{menu.title}</span>
          </li>
          </>
        ))}
      </ul>
      </div>
      
      <MainScreen />
      
      
    </div>
  );
}

export default App;
