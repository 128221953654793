import React from 'react'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as BsIcons from "react-icons/bs";
import * as BiIcons from "react-icons/bi";

export const SideMenu = [
  {
  title: "Αρχική",
  path: "/",
  icon: <AiIcons.AiFillHome />,
  cName: "nav-text",
  },
  {
  title: "Παραστατικά",
  path:"/parastatika",
  icon: <IoIcons.IoIosPaper />,
  cName: "nav-text",
  },
  {
  title: "Πελάτες",
  path: "/pelates",
  icon: <BsIcons.BsFillPeopleFill />,
  cName: "nav-text",
  },
  {
  title: "Είδη/Υπηρεσίες",
  path: "/eidh-yphresies",
  icon: <BsIcons.BsBoxSeam />,
  cName: "nav-text",
  },
  {
  title: "Αναφορές",
  path: "/anafores",
  icon: <IoIcons.IoMdFolderOpen />,
  cName: "nav-text",
  },
  {
  title: "E-shop",
  path: "/eshop",
  icon: <BsIcons.BsGlobe />,
  cName: "nav-text",
  },
  {
  title: "Παράμετροι",
  path: "/parametroi",
  icon : <BsIcons.BsDatabase />,
  cName: "nav-text",
  },
];