import React from 'react'
import { RxDashboard} from 'react-icons/rx';
import {IoNotificationsOutline} from 'react-icons/io5';
import * as AiIcons from "react-icons/ai";
function Header() {
  return (
    <div className="flex justify-between py-4 px-10 bg-white ">
      <div className="flex flex-row justify-center text-center cursor-pointer ">
      <AiIcons.AiFillHome className="p-2 text-3xl rounded-1g text-slate-400  hover:text-slate-700 "/>
      <h1 className="font-normal text-slate-400 text-sm mt-1"> / Αρχική</h1>
      </div>
      
      <div className="flex">
        <RxDashboard className="p-2 text-4xl rounded-1g cursor-pointer text-slate-400  hover:text-slate-700 "/>
        <IoNotificationsOutline className="p-2 text-4xl rounded-1g cursor-pointer text-slate-400 hover:text-slate-700"/>
      </div>
      
    </div>
  )
}

export default Header