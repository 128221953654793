import React from 'react'

function Footer() {
  return (
    <footer className="flex py-5 px-10 bg-white md:justify-center fixed bottom-0 w-screen">
        <div className="flex flex-row justify-center text-center mr-40">
            <span className="font-normal transition-colors hover:text-slate-500 focus:text-slate-500 text-sm mr-1">
                Copyright © TopSoft Cloud ERP 2024 |</span>
            
            <span className="flex font-normal transition-colors hover:text-slate-500 focus:text-slate-500 cursor-pointer text-sm">
               Version 1.1.0</span>
            
        </div>
    
    </footer>
    
  );
}

export default Footer